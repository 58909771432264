import { render, staticRenderFns } from "./quotationCost.vue?vue&type=template&id=de3c9536&scoped=true&"
import script from "./quotationCost.vue?vue&type=script&lang=js&"
export * from "./quotationCost.vue?vue&type=script&lang=js&"
import style0 from "./quotationCost.vue?vue&type=style&index=0&id=de3c9536&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3c9536",
  null
  
)

export default component.exports