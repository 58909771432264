<template>
	<el-dialog title="选择费用组" :visible.sync="dialogFormVisible" width="1000px">
		<div class="search">
      <div class="searchFl" v-if="pageData">
				<span style="margin-left:10px">起运港：{{pageData.from_port_name}}</span>
				<span style="margin-left:100px">目的港：{{pageData.dest_port_name}}</span>
      </div>
			<div>
        <el-button size="small" type="primary" @click="searchChange" v-show="showTable"
          >重新选择</el-button
        >
      </div>
    </div>
		<div class="tableBlock" v-show="!showTable" v-loading="loading">
			<el-table 
				:height="100"
				v-tableHeight="{ bottomOffset: 74 }"
				:data="tableData" style="width: 100%; margin-top:20px; font-size:13px" max-height="600" border>
				<el-table-column label="起运港" :show-overflow-tooltip="true">
					<template slot-scope="scope">{{ scope.row.from_port | textFormat }}</template>
				</el-table-column>
				<el-table-column label="中转港" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.trans_port | textFormat}}
					</template>
				</el-table-column>
				<el-table-column label="目的港：" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.dest_port | textFormat}}
					</template>
				</el-table-column>
				<el-table-column label="海运费" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.currency_code | textFormat}}
						{{scope.row.price_lcl_dangerous | textFormat}}
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click.native.prevent="getCostList(scope.row.freight_id)" type="text" >
							选择
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="tableBlock" v-show="showTable" v-loading="loading">
			<el-row>
				<el-col :span="24">
					请选择需要添加的费用项分组，可在添加费用分组后，将不需要的费用从页面删除。
				</el-col>
			</el-row>
			<el-row class="datalist">
				<el-checkbox-group v-model="checkList">
					<template v-for="(item,index) in filterList">
						<el-checkbox :key="index" :label="item.fee_type">{{item.fee_type}}</el-checkbox>
					</template>
				</el-checkbox-group>
			</el-row>
		</div>
		<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false" size='small'>取 消</el-button>
				<el-button type="primary" @click="handle_add" size='small'>确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import shipCompany from "~ymp/components/common/shipCompany"
export default {
	components:{ shipCompany },
	data() {
		return {
			dialogFormVisible: false,
			codeArr: [],
			tableData: [],
			currentPage: 1,
			total: 0,
			loading: false,
			pageData: {}, //页面传入参数
			showTable: false, 
			checkList: [],
			filterList: []
		}
	},
	watch: {

	},
	mounted() {

	},
	methods: {
		openDialog(pageData) {
			this.dialogFormVisible = true;
			this.checkList = [];
			this.filterList = [];
      this.showTable = false;
			this.pageData = pageData || {};
			this.getList();
		},
		async getCostList(id){
			if(!id){
				return false;
			}
			this.loading = true;
      let res = await this.$store.dispatch("API_bookingSpace/getEntrustFeeList",{
          entrust_kind: 2,
          freight_id: id
      })
      let list = [],
      obj = {};
      res.data.filter((it)=>{
        if(!obj.hasOwnProperty(it.fee_name)){
          obj[it.fee_name] = it.fee_name;
          list.push(it)
        }else{
          return;
        }
      })
			let filterObj = {};
			let filterList = list.reduce((cur,next)=>{
				filterObj[next.fee_type] ? "" : filterObj[next.fee_type] = true && cur.push(next);
				return cur;
			},[])
			this.filterList = filterList;
			this.costList = list;
			this.loading = false;
			this.showTable = true;
    },
		searchChange() {
			this.checkList = [];
			this.filterList = [];
      this.showTable = false;
    },
		handle_add(){
			let that = this;
			let list = [];
			if(!that.checkList.length){
				this.$message.warning('请至少选择一个费用项分组!')
				return false;
			}
			for(let j=0; j < that.checkList.length; j++){
				let item = that.checkList[j];
				for(let i=0; i < that.costList.length; i++){
					if(item == that.costList[i].fee_type){
						list.push(that.costList[i])
					}
				}
			}
			this.$emit('addCostList', list)
			this.dialogFormVisible = false;
		},
		async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
				from_port: this.pageData.from_port,
				dest_port: this.pageData.dest_port,
        nopage:0,
      };
      let result = await this.$store.dispatch(
        "API_bookingSpace/getSellToClientLclFreight",
        params
      );
      this.loading = false;
      if (result && result.success) {
				result.data.forEach(element => {
					try {
						element.sea_freight = JSON.parse(element.sea_freight)
					} catch (error) {
						element.sea_freight = []
					}
				});
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
		handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
	}
}
</script>
<style lang="less" scoped>
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 200px;
      padding: 0 6px;
    }
  }
}
.datalist .el-checkbox-group{
		display: flex;
    flex-direction: row;
		flex-wrap: wrap;
    padding: 20px;
		line-height: 26px;
    max-height: 300px;
}
/deep/ .el-dialog__body {
	padding-bottom: 60px;
}
</style>